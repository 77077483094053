<template>
  <div>
    <SimpleTableForm
      :id="id"
      title="Create Material"
      :table-config="tableConfig"
      :table-config-categories="tableConfigCategories"
      :return-route="adminRoute"
      :server-type="serverType"
      :loading="loading"
      @created="$emit('created')"
    />
  </div>
</template>

<script>

import SimpleTableForm from '@/components/form/SimpleTableForm.vue'
import { materialsForm } from './constants'

export default {
  name: 'MaterialsForm',
  namespaced: true,
  components: { SimpleTableForm },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      tableConfig: materialsForm,
      tableConfigCategories: {},
      serverType: 'materials',
      adminRoute: '/admin/materials'
    }
  },
  async created () {
    this.tableConfigCategories['materialCategory'] = (await this.$axios.get('/material_categories')).data
    this.tableConfigCategories['materialUseCategories'] = (await this.$axios.get('/material_use_categories')).data
    this.loading = false
  }
}
</script>
