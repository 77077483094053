<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="materials"
      :items-per-page="10"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="headline pb-0 pt-2">
            Materials
          </v-toolbar-title>
          <v-spacer />
          <v-dialog
            v-model="showForm"
            max-width="500"
          >
            <template #activator="{ on }">
              <v-btn
                color="shamrock"
                dark
                class="mb-2"
                elevation="0"
                v-on="on"
              >
                New Material
              </v-btn>
            </template>
            <NewMaterialForm
              @created="handleCreatedMaterial"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <EditAndDeleteButtons
          :edit-href="`/admin/materials/${item._id}`"
          @delete="deleteMaterial(item._id)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import { mapActions } from 'vuex'
import NewMaterialForm from './MaterialForm'
import EditAndDeleteButtons from '@/components/tables/EditAndDeleteButtons.vue'

export default {
  name: 'AdminMaterials',
  components: {
    NewMaterialForm,
    EditAndDeleteButtons
  },
  data () {
    return {
      loading: true,
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'CC - Material Id',
          value: 'ccMaterialId'
        },
        {
          text: 'Material Category',
          value: 'materialCategory'
        },
        {
          text: 'Low Carbon Factor',
          value: 'lowCarbonFactor'
        },
        {
          text: 'Median Carbon Factor',
          value: 'medianCarbonFactor'
        },
        {
          text: 'High Carbon Factor',
          value: 'highCarbonFactor'
        },
        {
          text: 'Density',
          value: 'density'
        },
        {
          text: 'Mass',
          value: 'mass'
        },
        {
          text: 'Use',
          value: 'materialUseCategories'
        },
        {
          text: 'Carbon Stored',
          value: 'carbonStored'
        },
        {
          text: 'Replacement Cycles',
          value: 'replacementCycles'
        },
        {
          text: 'Notes',
          value: 'notes'
        },
        {
          text: 'Citations',
          value: 'citations'
        },
        {
          value: 'actions',
          sortable: false
        }
      ],
      materials: [],
      showForm: false
    }
  },
  async created () {
    await this.fetchMaterialCategories() // defined in MaterialCategories vue
    await this.fetchMaterialUseCategories() // defined in MaterialCategories vue
    await this.fetchMaterials()
    this.loading = false
  },
  methods: {
    ...mapActions(['showSnackbar']),
    async fetchMaterialCategories () {
      const { data: materialCategories } = await this.$axios.get('/material_categories')
      this.materialCategories = materialCategories.map(materialCategory => ({
        ...materialCategory
      }))
    },
    async fetchMaterialUseCategories () {
      const { data: materialUseCategories } = await this.$axios.get('/material_use_categories')
      this.materialUseCategories = materialUseCategories.map(materialUseCategory => ({
        ...materialUseCategory
      }))
    },
    async fetchMaterials () {
      const { data: materials } = await this.$axios.get('/materials')
      const mergedMaterials = materials.map((material) => ({
        ...material,
        materialCategory: this.materialCategories.find((category) => category._id === material.materialCategory)?.name,
        materialUseCategories: this.materialUseCategories.filter((category) => (material.materialUseCategories || []).includes(category._id))?.map((category) => category.name)
      }))

      this.materials = mergedMaterials.map(material => ({
        ...material
      }))
    },
    async deleteMaterial (id) {
      this.loading = true
      try {
        await this.$axios.delete(`/materials/${id}`)
        await this.fetchMaterials()
      } catch (err) {
        this.showSnackbar({ color: 'error', message: 'Unable to delete material. Please try again.' })
        throw err
      }
      this.loading = false
    },
    handleCreatedMaterial () {
      this.showForm = false
      this.fetchMaterials()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
